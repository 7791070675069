<template>
	<div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="规格名称">
					<el-input v-model="searchForm.name" placeholder="规格名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="set_meal_name" label="套餐名称" width="280">
			</el-table-column>
			<el-table-column prop="name" label="规格名称" width="280">
			</el-table-column>
			<el-table-column prop="price" label="价格">
			</el-table-column>
			<el-table-column prop="sales_commission" label="销售提成" width="180">
				<template slot-scope="scope">
					<div>
						{{scope.row.sales_commission|toFixed2}}
						<el-button icon="el-icon-edit" circle size="mini" @click="showEdit(scope.row)"></el-button>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label="创建时间">
			</el-table-column>
			<el-table-column prop="status" label="状态" width="100">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.status">正常</div>
					<div class="text-gray" v-else>停用</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="200">
				<template slot-scope="scope">
					<el-button @click="showDetail(scope.row)" size="mini">查看</el-button>
					<!-- <el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm> -->
					<el-button @click="publish(scope.row)" size="mini" v-if="scope.row.status" type="danger">停用</el-button>
					<el-button @click="publish(scope.row)" size="mini" v-else type="success">启用</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
		<!-- 查看规格详情 -->
		<el-dialog title="规格详情" :visible.sync="detailDialog.visible" append-to-body :close-on-click-modal="false">
			<div>
				<el-form ref="form" :model="detailDialog.detail" label-width="80px" v-loading="detailDialog.loading">
					<el-form-item label="服务项目">
						<div class="items auto-height margin-top">
							<div class="item not-select" v-for="item in detailDialog.detail.serve" :key="item.id">
								<el-image :src="item.cover" fit="cover"></el-image>
								<div class="cont">
									<div class="tit line-2">
										<span v-if="item.type==1" class="text-blue">[品牌]</span>
										<span v-if="item.type==2" class="text-green">[自营]</span>
										{{item.name}}
									</div>
									<div class="price-box">
										<div class="price">￥{{item.price}}</div>
										<div class="count">
											数量：{{item.number}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-form-item>
					<el-form-item label="商品">
						<div class="items auto-height margin-top">
							<div class="item not-select" v-for="item in detailDialog.detail.goods" :key="item.id">
								<el-image :src="item.cover" fit="cover"></el-image>
								<div class="cont">
									<div class="tit line-2">
										<span v-if="item.type==1" class="text-blue">[品牌]</span>
										<span v-if="item.type==2" class="text-green">[自营]</span>
										{{item.name}}
										<div class="text-blue">[{{item.sku||'默认'}}]</div>
									</div>
									<div class="price-box">
										<div class="price">￥{{item.price}}</div>
										<div class="count">
											数量：{{item.number}}
										</div>
									</div>
								</div>
							</div>
						</div>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" @click="detailDialog.visible = false">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 编辑销售提成 -->
		<el-dialog title="编辑" :visible.sync="editDialog.visible" append-to-body :close-on-click-modal="false">
			<el-form :model="editDialog.form" @submit.native.prevent label-width="100px" label-position="left">
				<el-form-item label="套餐名称">
					{{editDialog.form.set_meal_name}}
				</el-form-item>
				<el-form-item label="规格名称">
					{{editDialog.form.name}}
				</el-form-item>
				<el-form-item label="售价">
					{{editDialog.form.price}}
				</el-form-item>
				<el-form-item label="销售提成">
					<el-input-number v-model="editDialog.form.sales_commission" :min="0" label="销售提成" :precision="2"></el-input-number>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="editDialog.visible = false">取 消</el-button>
				<el-button type="primary" @click="editSubmit()" :loading="editDialog.loading">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				searchForm: {
					type: 1, // 1 自营 2 品牌
					name: '', // 否 string
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
				detailDialog:{
					loading: false,
					visible: false,
					detail:{}
				},
				editDialog: {
					visible: false,
					loading: false,
					form: {}
				}
			};
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			editSubmit(){
				this.editDialog.loading = true;
				this.$api.group.publishBrandGroupItem({
					id: this.editDialog.form.id,
					sales_commission: this.editDialog.form.sales_commission
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
						this.editDialog.loading = false;
						this.editDialog.visible = false;
						this.search()
					} else {
						this.$message.error(res.msg)
					}
				}).catch(err => {
					this.$alert(err)
				})
			},
			showEdit(item){
				this.editDialog.visible = true;
				this.editDialog.form = this.$util.deepClone(item);
			},
			showDetail(item){
				this.detailDialog.visible = true;
				this.detailDialog.loading = true;
				this.$api.group.getBrandGroupItem({id: item.specs_id}).then(res=>{
					this.detailDialog.loading = false;
					this.detailDialog.detail = res.data;
				})
			},
			search() {
				this.loading = true;
				this.$api.group.getBrandGroupItems(this.searchForm).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			publish(item) {
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.group.publishBrandGroupItem({
					id: item.id,
					status: item.status
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
	.items {
		display: flex;
		flex-wrap: wrap;
		height: 500px;
		overflow: auto;
	
		&.auto-height {
			height: auto;
	
			.item {
				width: 400px;
	
				.cont {
					.tit {
						height: 55px;
					}
				}
			}
	
		}
	
		.item {
			width: 300px;
			margin: 0 20px 20px 0;
			height: 90px;
			border: 2px solid #ccc;
			border-radius: 10px;
			padding: 10px;
			display: flex;
			cursor: pointer;
	
			&.cur {
				border: 2px solid var(--green);
			}
	
			.cont {
				flex: 1;
				margin-left: 10px;
				position: relative;
	
				.tit {
					height: 70px;
					line-height: 20px;
					font-size: 16px;
				}
	
				.price-box {
					display: flex;
					justify-content: space-between;
					align-items: center;
					position: relative;
	
	
					.price {
						font-size: 20px;
						line-height: 20px;
						color: var(--orange);
					}
	
					.count {
						display: flex;
						justify-content: space-between;
						align-items: center;
						font-size: 24px;
						color: var(--blue);
	
						i {
							// font-size: 30px;
							cursor: pointer;
						}
	
						.el-input {
							width: 43px;
							margin: 0 5px;
							font-size: 20px;
						}
	
						.el-input>>>input {
							padding: 0;
							text-align: center;
						}
	
					}
				}
	
			}
	
			.el-image {
				width: 90px;
				height: 90px;
				border-radius: 5px;
			}
		}
	}
</style>
