<template>
	<div>
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="规格名称">
					<el-input v-model="searchForm.name" placeholder="规格名称"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search()">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button icon="el-icon-plus" @click="toEdit()">新增规格</el-button>
					<!-- <el-button type="primary" icon="el-icon-edit" circle></el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="50">
			</el-table-column>
			<el-table-column prop="set_meal_name" label="套餐名称" width="280">
			</el-table-column>
			<el-table-column prop="name" label="规格名称" width="280">
			</el-table-column>
			<el-table-column prop="price" label="价格">
			</el-table-column>
			<el-table-column prop="created_at" label="创建时间">
			</el-table-column>
			<el-table-column prop="status" label="状态" width="100">
				<template slot-scope="scope">
					<div class="text-green" v-if="scope.row.status">正常</div>
					<div class="text-gray" v-else>停用</div>
				</template>
			</el-table-column>
			<el-table-column fixed="right" label="操作" width="180">
				<template slot-scope="scope">
					<el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
					<!-- <el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm> -->
					<el-button @click="publish(scope.row)" size="mini" v-if="scope.row.status" type="danger">停用</el-button>
					<el-button @click="publish(scope.row)" size="mini" v-else type="success">启用</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes" :page-size="searchForm.page_size"
			layout="total, sizes, prev, pager, next, jumper" :total="total" @size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				searchForm: {
					type: 1, // 1 自营 2 品牌
					name: '', // 否 string
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
			};
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			search() {
				this.loading = true;
				this.$api.group.getGroupItems(this.searchForm).then(res => {
					this.total = res.data.count;
					this.tableData = res.data.data;
					this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			toEdit(item) {
				if (item) {
					this.$router.push(`/groupItem/edit/${item.id}`)
				} else {
					this.$router.push(`/groupItem/edit`)
				}
			},
			del(item) {
				const loading = this.$loading()
				this.$api.group.del({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(`操作成功`);
						this.search()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			publish(item) {
				let status = item.status;
				item.status = item.status == 1 ? 0 : 1;
				this.$api.group.saveItem({
					id: item.id,
					status: item.status
				}).then(res => {
					if (res.code == 200) {
						this.$message.success('操作成功')
					} else {
						this.$message.error(res.msg)
						item.status = status;
					}
				}).catch(err => {
					item.status = status;
					this.$alert(err)
				})
			}
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
